<template>
  <section class="register">
    <div class="grid-x header">
      <div class="cell auto">
        <div class="logo-header">
          <a href="https://doppio.sh/">
            <logo />
          </a>
        </div>
      </div>
      <div class="cell shrink">
        <div class="logo-header">
          <router-link :to="{ name: 'login' }" class="link">I have an account</router-link>
        </div>
      </div>
    </div>

    <div class="grid-x content">
      <div class="cell auto illustration-container">
        <video autoplay loop muted width="100%" height="auto">
          <source src="doppio.webm" type="video/webm; codecs='vp8, vorbis'"/>
          <source src="doppio.mp4" type="video/mp4"/>
          The real tiny Doppio factory that creates one by one PDF documents from HTML files
        </video>
      </div>
      <div class="cell auto form-container">
        <form v-if="!isRegistered" ref="register" @submit.prevent="register(user)">
          <div class="logo-container">
            <h1>Sign up</h1>
          </div>
          <div class="btn-signin-google">
            <a :href="googleLoginUrl" class="google-link">
              <app-button size="large"><google-icon />Sign in with Google</app-button>
            </a>
          </div>
          <div class="divider">
            <p>Or sign up with email</p>
          </div>
          <div class="input-email">
            <app-label required for="email">Email</app-label>
            <app-input
              autocomplete="email"
              type="email"
              inputmode="email"
              :maxlength="128"
              required
              v-model="user.email"
              id="email"
            />
          </div>
          <div class="input-password">
            <app-label required for="password">Password</app-label>
            <app-input
              autocomplete="current-password"
              type="password"
              required
              :minlength="8"
              :maxlength="64"
              v-model="user.password"
              id="password"
            />
            <p>Must be at least 8 characters long</p>
          </div>
          <div class="register-error" v-if="errorMessage">
            <p>{{ errorMessage }}</p>
          </div>
          <div class="btn-get-started">
            <app-button type="submit" size="large" :loading="isLoading">Get started</app-button>
          </div>
        </form>
        <div v-else class="welcome">
          <h1>Welcome to Doppio!</h1>
          <div>
            <p>
              Registration successful! <br/>
              We have sent you an email with a link to activate your account.<br/><br/>
              Please check your SPAM folder.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import auth from '@/services/auth';
import userApi from '@/services/api/user';
import logo from '@/assets/img/logo.svg?inline';
import googleIcon from '@/assets/img/google.svg?inline';

export default {
  name: 'register',
  components: {
    logo,
    'google-icon': googleIcon,
  },
  data() {
    return {
      googleLoginUrl: `${process.env.VUE_APP_API_URL}/auth/google`,
      user: {
        email: '',
        password: '',
      },
      isLoading: false,
      isRegistered: false,
      errorMessage: null,
    };
  },
  async mounted() {
    if (this.$route.query.token) {
      try {
        // On ajoute le token
        await auth.loginFromToken(this.$route.query.token);

        // On fait une requête pour vérifier que l'url est bien toujours valide
        const user = await userApi.getMe();

        if (user && user.userId) {
          this.$router.push({ name: 'home' });
        }
      } catch (er) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to register your account',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    }
  },
  methods: {
    async register(user) {
      if (this.$refs.register.checkValidity()) {
        this.isLoading = true;
        this.errorMessage = null;

        try {
          await userApi.register({
            ...user,
            firstname: '',
            lastname: '',
          });

          this.isRegistered = true;
        } catch (error) {
          this.errorMessage = error.response.data.message;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.$refs.register.reportValidity();
      }
    },
  },
};
</script>

<style lang="sass">
.register

  @media (max-width: 767px)
    overflow-x: hidden
    padding: 0

  @include page
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  padding: 40px 50px
  z-index: 0
  background-color: $primary100
  overflow: auto

  video
    height: 80vh
    max-height: 600px

  .grid-x
    width: 100%

  .divider
    display: flex
    justify-content: center
    margin-bottom: 32px
    p
      position: relative
      color: $primary20
      padding: 0 0.5rem
      &::after,
      &::before
        content: ""
        position: absolute
        top: 50%
        width: 100%
        height: 1px
        background-color: $primary20
      &::after
        left: -100%
      &::before
        right: -100%
  h1
    color: $white
  .google-link button
    background-color: white !important
    color: black
    display: flex
    justify-content: center
    align-items: center
    svg
      width: 16px
      margin-right: 10px
      path
        stroke: none
  .background-image
    position: absolute
    z-index: -1
    top: 0
    left: 0
    width: 50%
  .link
    @include link
    color: white
    text-decoration: underline
    padding-top: 5px
    display: block
    &:hover
      text-decoration: none
  .logo-header
    svg
      width: 110px
  .illustration-container
    display: flex
    align-items: center
    justify-content: center
    padding-top: 50px
    min-height: calc(100vh - 170px)

  .form-container
    display: flex
    align-items: center
    justify-content: center
  form
    width: 100%
    margin: 0 70px
    max-width: 400px
    .btn-signin-google
      margin-bottom: 40px
    .input-email
      margin-bottom: 32px
    .input-password
      margin-bottom: 8px
      > p
        margin-top: 0.5rem
        color: $primary30
    .btn-get-started
      margin-top: 32px
    .register-error
      > p
        color: $warning
  .logo-container
    margin-bottom: 64px
    display: flex
    justify-content: center
    padding: 0
  .welcome
    p
      margin-top: 1.5rem
      font-size: 115%
      line-height: 1.5
  @media (max-width: 767px)
    .header
      padding: 16px 24px
    .content
      flex-direction: column
      height: 100%
      padding: 0
      .illustration-container
        padding: 0
        min-height: auto
      form
        margin: 0 25px
      .app-button
        margin: 0
      .logo-container
        display: none
</style>
